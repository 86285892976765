import Vue from "vue";
import Router from "vue-router";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("./views/home"),
      meta: { authRequired: true },
    },
    {
      path: "/login",
      component: () => import("./views/login"),
      meta: { authRequired: false },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: { authRequired: false },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/register",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/email",
      component: () => import("./views/email"),
      meta: { authRequired: true },
    },
    {
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: { authRequired: false },
    },
    {
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: { authRequired: false },
    },
    {
      path: "/account",
      component: () => import("./views/account/account"),
      meta: { authRequired: true },
    },
    {
      path: "/account/documents",
      component: () => import("./views/account/documents"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: { authRequired: true },
    },
    {
      path: "/membership",
      component: () => import("./views/membership"),
      meta: { authRequired: true },
    },
    {
      path: "/plans",
      component: () => import("./views/plans/index"),
      meta: { authRequired: true },
    },
    {
      path: "/plans/order/:id",
      component: () => import("./views/plans/order"),
      meta: { authRequired: true },
    },
    {
      path: "/tools",
      component: () => import("./views/tools"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/deposits",
      component: () => import("./views/wallet/deposits-list"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/deposits/new",
      component: () => import("./views/wallet/deposits-new"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/deposits/:id",
      component: () => import("./views/wallet/deposits-order"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: { authRequired: true },
    },
    {
      path: "/network/sponsored",
      component: () => import("./views/network/sponsored"),
      meta: { authRequired: true },
    },
    {
      path: "/network/team",
      component: () => import("./views/network/team"),
      meta: { authRequired: true },
    },
    {
      path: "/network/binary",
      component: () => import("./views/network/binary"),
      meta: { authRequired: true },
    },
    {
      path: "/network/binary/:id",
      component: () => import("./views/network/binary"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/indicacao",
      component: () => import("./views/reports/indicacao"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/equiparacao",
      component: () => import("./views/reports/equiparacao"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/residual",
      component: () => import("./views/reports/residual"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/lideranca",
      component: () => import("./views/reports/lideranca"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/graduation",
      component: () => import("./views/reports/graduation"),
      meta: { authRequired: true },
    },
    {
      path: "/store/orders",
      component: () => import("./views/store/orders"),
      meta: { authRequired: true },
    },
    {
      path: "/store/order/:id",
      component: () => import("./views/store/order"),
      meta: { authRequired: true },
    },
    {
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: { authRequired: true },
    },
    {
      path: "/faq",
      component: () => import("./views/faq"),
      meta: { authRequired: true },
    },
    {
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: { authRequired: true },
    },
    {
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: { authRequired: true },
    },
    {
      path: "/informatives",
      component: () => import("./views/informatives/index"),
      meta: { authRequired: true },
    },
    {
      path: "/informatives/:id",
      component: () => import("./views/informatives/view"),
      meta: { authRequired: true },
    },
    {
      path: "/events",
      component: () => import("./views/events/index"),
      meta: { authRequired: true },
    },
    {
      path: "/events/:id",
      component: () => import("./views/events/view"),
      meta: { authRequired: true },
    },
    {
      path: "/courses",
      component: () => import("./views/courses/list"),
      meta: { authRequired: true },
    },
    {
      path: "/courses/:course",
      component: () => import("./views/courses/view"),
      meta: { authRequired: true },
    },
    {
      path: "/courses/:course/:module/:lesson",
      component: () => import("./views/courses/view"),
      meta: { authRequired: true },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: { authRequired: false },
    },
    {
      path: "/:sponsor",
      redirect: "/register/:sponsor",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  document.title = process.env.VUE_APP_TITLE;
  next();

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          router.app.$store.state.account.user = response.data.user;

          if (
            response.data.user.email.status == 'pending' &&
            to.path != "/email"
          ) {
            return next("/email");
          } else if (
            response.data.user.membership &&
            response.data.user.membership.status &&
            response.data.user.membership.status == 'pending' &&
            response.data.user.membership.order &&
            response.data.user.membership.order.id &&
            response.data.user.email.status == 'validated' &&
            to.path != "/membership"
          ) {
            return next("/membership");
          } else {
            next();
          }
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  } else {
    next();
  }
});

export default router;
